import React, { useEffect, useState } from "react";
import fb, { getDb } from "../firebase";
import { getDatabase, ref, onValue, update } from "firebase/database";
import { Routes } from "../Constants";
import Live from "./Live";
import { getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup } from "firebase/auth";
import { Alert, Button } from "react-bootstrap";
import { GlobalSettings, LiveStats } from "../../../Widget/src/interfaces/UserPresence";
import StatusButton, { Status } from "./StatusButton";
import Modal from "react-bootstrap/Modal";
import { Formik, Field, Form } from "formik";
import ViewDropdown from "./ViewDropdown";
import QueueModeDropdown from "./QueueModeDropdown";

interface DashboardProps {
	path: string;
}

const owners = [
	"jared@brushfire.com",
	"jorin@brushfire.com",
	"david@brushfire.com",
	"pete@brushfire.com",
	"clayton@brushfire.com",
	"minjae@brushfire.com",
	"matthewlamperski@brushfire.com",
];

const Dashboard: React.FC<DashboardProps> = ({ path }) => {
	const [showNav, setShowNav] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [allow, setAllow] = useState<boolean>();
	const [settings, setSettings] = useState<GlobalSettings>();
	const [liveStats, setLiveStats] = useState<LiveStats>();
	const [syncLive, setSyncLive] = useState(true);

	const auth = getAuth();
	const db = getDb(path === Routes.dashboardStaging);

	useEffect(() => {
		onAuthStateChanged(auth, (user) => {
			if (!!user) {
				if ((user?.email ?? "").endsWith("brushfire.com")) {
					setAllow(true);
				} else {
					setAllow(false);
				}
			} else {
				signInWithGoogle();
			}
		});
	}, []);

	useEffect(() => {
		const settingsRef = ref(db, "/settings");
		return onValue(settingsRef, (snapshot) => {
			if (snapshot.exists()) {
				const globalSettings = snapshot.val() as GlobalSettings;
				if (globalSettings) {
					setSettings(globalSettings);
				} else {
					//The settings are gone? This should never happen, so throw an error
				}
			} else {
				//The settings are gone? This should never happen, so throw an error
			}
		});
	}, []);

	useEffect(() => {
		const liveRef = ref(db, "/live");
		if (syncLive) {
			return onValue(liveRef, (snapshot) => {
				if (snapshot.exists()) {
					const liveStats = snapshot.val() as LiveStats;
					if (liveStats) {
						setLiveStats(liveStats);
					} else {
						//The settings are gone? This should never happen, so throw an error
					}
				} else {
					//The settings are gone? This should never happen, so throw an error
				}
			});
		}
	}, [syncLive]);

	const signInWithGoogle = () => {
		const provider = new GoogleAuthProvider();
		signInWithPopup(auth, provider);
	};

	const onClients = Object.keys(settings?.clients ?? {}).filter((clientKey) => settings?.clients[clientKey].on);

	if (allow === undefined) {
		return null;
	}

	if (!allow) {
		return (
			<div className="">
				<div className="well well-xs absolute-center">
					<div className="well-content ta-center">
						<h1>BRUSHFIRE Q</h1>
						{(auth.currentUser?.uid !== undefined ||
							(!!auth.currentUser && !auth.currentUser.email?.endsWith("brushfire.com"))) && (
							<Alert variant="danger">Invalid account</Alert>
						)}

						<Button variant="primary" onClick={signInWithGoogle} className="d-block m-top-medium w-100">
							Sign In
						</Button>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className={`page queue-dashboard ${showNav ? "show-nav" : ""}`}>
			<header>
				{/* <div
					className="hamburger"
					onClick={() => {
						setShowNav(!showNav);
					}}
				>
					<i className="fal fa-bars"></i>
				</div> */}
				<img
					alt="Brushfire Logo"
					className="queue-dashboard-logo"
					src="https://brushfirecontent.blob.core.windows.net/0000/Media/brushfire-logo-bw.png"
				/>
			</header>

			<div className="header-right">
				{owners.includes(auth?.currentUser?.email ?? "") && (
					<>
						<QueueModeDropdown path={path} settings={settings} />
						<div className="dataview-dropdown no-icon" onClick={() => setShowModal(true)}>
							<button className="trigger dropdown-toggle btn btn-light" style={{ height: "45px" }}>
								<i className="fas fa-cog"></i>
							</button>
						</div>
					</>
				)}
				<div className="avatar">
					<img src={auth.currentUser?.photoURL ?? ""} alt={auth.currentUser?.displayName ?? "Avatar"} />
				</div>
			</div>
			<section className={`page-body ${showNav ? "nav-active" : ""}`}>
				{path === Routes.dashboardSettings && (
					<div className="row">
						<div className={`col-6 ta-left`}>
							<StatusButton
								text={
									settings?.queueState === 1
										? `Auto: ${onClients.length > 0 ? "On" : "Off"}`
										: settings?.queueState === 2
										? "Forced: On"
										: "Off"
								}
								status={
									settings?.queueState === 1
										? onClients.length > 0
											? Status.on
											: Status.waiting
										: settings?.queueState === 2
										? Status.on
										: Status.off
								}
								onClick={() => {
									setShowModal(true);
								}}
							/>
						</div>
						<div className={`col-6 ta-right`}>
							<button
								className="btn btn-primary"
								onClick={() => {
									setSyncLive(!syncLive);
								}}
							>
								{syncLive ? "Stop" : "Start"}
							</button>
						</div>
					</div>
				)}
				<Live settings={settings} liveStats={liveStats} setShowModal={setShowModal} path={path} />
			</section>
			<Modal
				show={!!showModal}
				onHide={() => {
					setShowModal(false);
				}}
				centered
			>
				{!!showModal && (
					<Formik
						initialValues={{
							status: settings?.maintenance.on
								? "maintenance"
								: settings?.queueState === 1
								? settings.paused
									? "auto-paused"
									: "auto"
								: settings?.queueState === 2
								? settings.paused
									? "on-paused"
									: "on"
								: "off",
							heading: settings?.heading ?? "",
							body: settings?.body ?? "",
							maintenanceHeading: settings?.maintenance.heading ?? "",
							maintenanceBody: settings?.maintenance.body ?? "",
							maxLetIn: settings?.maxLetIn,
							internetLimit: settings?.internetLimit,
							clientQueuePercent: (settings?.clientQueuePercent ?? 0) * 100,
							proactiveQueuePercent: (settings?.proactiveQueuePercent ?? 0) * 100,
						}}
						onSubmit={async (values) => {
							const settingsRef = ref(db, `/settings`);
							update(settingsRef, {
								queueState: settings?.queueState,
								heading: values.heading,
								body: values.body,
								paused: settings?.paused,
								maintenance: {
									...settings?.maintenance,
									heading: values.maintenanceHeading,
									body: values.maintenanceBody,
									on: settings?.maintenance.on,
								},
								maxLetIn: values?.maxLetIn,
								internetLimit: values.internetLimit,
								clientQueuePercent: !!values?.clientQueuePercent
									? values.clientQueuePercent / 100
									: undefined,
								proactiveQueuePercent: !!values.proactiveQueuePercent
									? values.proactiveQueuePercent / 100
									: undefined,
							})
								.then(() => {
									setShowModal(false);
								})
								.catch((err) => {
									console.log(err);
									alert("Something went wrong. Check the console for more info.");
								});
						}}
					>
						{({ values, isSubmitting }) => (
							<Form>
								<Modal.Header>
									<Modal.Title>Queue Settings</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<div role="group" aria-labelledby="my-radio-group">
										{values.status === "maintenance" ? (
											<>
												<div className="field">
													<label htmlFor="maintenanceHeading">Maintenance Heading</label>
													<Field
														placeholder="Message heading"
														name="maintenanceHeading"
														className="d-block w-100 m-top"
													/>
												</div>
												<div className="field">
													<label htmlFor="maintenanceBody">Maintenance Body</label>
													<Field
														placeholder="Message body"
														as="textarea"
														name="maintenanceBody"
														className="d-block w-100 m-top"
													/>
												</div>
											</>
										) : (
											<>
												<div className="field">
													<label htmlFor="message">Custom Queue Heading</label>
													<Field
														placeholder="Message heading"
														name="heading"
														className="d-block w-100 m-top"
													/>
												</div>
												<div className="field">
													<label htmlFor="message">Custom Queue Message</label>
													<Field
														placeholder="Message body"
														as="textarea"
														name="body"
														className="d-block w-100 m-top"
													/>
												</div>
												<div className="field">
													<label htmlFor="message">Max Number Of Users To Let In</label>
													<Field
														placeholder="500"
														name="maxLetIn"
														type="number"
														className="d-block w-100 m-top"
													/>
												</div>
												<div className="field">
													<label htmlFor="message">Max Number Of Internet Users</label>
													<Field
														placeholder="500"
														name="internetLimit"
														type="number"
														className="d-block w-100 m-top"
													/>
												</div>
												{(values?.status === "auto" || values.status === "auto-paused") && (
													<div className="field">
														<label htmlFor="message">
															Percentage of Users Allowed Per Client
														</label>
														<Field
															placeholder="10"
															name="clientQueuePercent"
															type="number"
															max="100"
															className="d-block w-100 m-top"
														/>
													</div>
												)}
												{(values?.status === "auto" || values.status === "auto-paused") && (
													<div className="field">
														<label htmlFor="message">Proactive Queue Percent Limit</label>
														<Field
															placeholder="90"
															name="proactiveQueuePercent"
															type="number"
															max="100"
															className="d-block w-100 m-top"
														/>
													</div>
												)}
											</>
										)}
									</div>
								</Modal.Body>
								<Modal.Footer>
									<button
										type="submit"
										className={`btn footer-btn ${isSubmitting ? "disabled" : ""}`}
									>
										{isSubmitting ? "Saving..." : "Save"}
									</button>
								</Modal.Footer>
							</Form>
						)}
					</Formik>
				)}
			</Modal>
		</div>
	);
};

export default Dashboard;
