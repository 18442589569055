export interface EventData {
	id?: string;
	eventNumber?: string;
	clientId?: string;
	clientKey?: string;
	title?: string;
	subtitle?: string;
	urlKey?: string;

	paymentProfileId?: string;
	categoryId?: number;
	isAssigned?: boolean;
	verbiage?: "TIX" | "REG";
	publishShowAt?: Date;
	publishHideAt?: Date;
	locationName?: string;
	street?: string;
	city?: string;
	region?: string;
	country?: string;
	postalCode?: string;
	locationDetails?: string;
	mapAddress?: string;
	website?: string;
	useMap?: boolean;
	coordinates?: string;
	timeZoneId?: string;
	overallCapacity?: number;
	dateInfo?: string;

	eventCode?: string;
	contactPhone?: string;
	contactEmail?: string;
	notifyEmail?: string;

	managerEmail?: string;
	ticketLimit?: number;
	taxRate?: number;
	acctCode?: string;

	onPortal?: boolean;
	dates?: EventDateItem[];

	attendeeTypes?: AttendeeTypeItem[];
	deliveryMethods?: EventShippingItem[];
	eventThemeJson?: ThemeItem;
	homeUrl?: string;

	deliveryType?: "none" | "eticket" | "more";

	eventDescription?: string;
	privateEvent?: boolean;

	//headerImage?: string;
	//mobileHeaderImage?: string;
	emailHeaderImage?: string;

	isDraft?: boolean;

	extraEventData?: ExtraEventData;
	hasSubscriptions?: boolean;
	hasLayouts?: boolean;
	isInclusiveFees?: boolean;
	isInclusiveTaxes?: boolean;

	isFlexPass?: boolean;
	isCapacityManaged?: boolean;
	isTimeSlot?: boolean;
}

export interface EventDateItem {
	doorsAt?: Date;
	startsAt?: Date;
	endsAt?: Date | null;
}

export interface ExtraEventData {
	accessCodes?: boolean;
	artwork?: boolean;
	comments?: string;
	deliveryMethods?: boolean;
	estimatedAttendance?: number;
	groups?: boolean;
	isMultipleEvents?: boolean;
	isOngoing?: boolean;
	lastStep?: string;
	layoutId?: number;
	layoutImage?: string;
	needsReview?: boolean;
	onSaleDate?: Date | string;
	paymentMethods?: boolean;
	promotions?: boolean;
	questions?: boolean;
	seatingLayout?: boolean;
	somethingElse?: boolean;
	eventApp?: boolean;
	attendeeSupport?: boolean;
	onSiteSupport?: boolean;
	onlineEventPlatform?: boolean;
	status?: SaveStatus;
	typeOfUrlKey?: "custom" | "select";
	attendeeFields: boolean;
	buyerFields: boolean;
	isFromTemplate?: boolean;
	isLayoutFromEvent?: boolean;
	feeMethod?: string;
	fee?: number;
	feePercent?: number;
	taxRate?: number;
}

export enum SaveStatus {
	New,
	Creating,
	Created,
	Updating,
	Updated,
	Submitting,
	Submitted,
	Publishing,
	Published,
}

export interface AttendeeTypeItem {
	id?: string;
	eventId?: string;
	name?: string;
	description?: string;
	adminShowA?: Date;
	adminHideAt?: Date;
	internetShowAt?: Date;
	internetHideAt?: Date;
	isHidden?: boolean;
	capacity?: number;
	roleName?: string;
	amount?: number;
	isPercentage?: boolean;
	fee?: number | null;
	feePercent?: number | null;
	allowCustomAmount?: boolean;
	minQuantity?: number;
	maxQuantity?: number;
	bypassGroupReq?: boolean;
	noChangesAfter?: Date;
	custom1?: string;
	custom2?: string;
	custom3?: string;
	billingMultiplier?: number;
	displayOrder?: number;
	paymentAmounts?: string;
	paymentsDue?: Date;
	acctCode?: string;
	groupingText?: string;

	giftingEnabled?: boolean;
	preRegEnabled?: boolean;
	copyEnabled?: boolean;

	typeKind?: string;

	sharedCapacityId?: string;
	sharedCapacityName?: string;
	sharedCapacity?: number;

	kind?: string;

	prices?: string[];
	fields?: string[];

	allowedAmounts?: number[];

	flexTotal?: number;
	flexPer?: number;
	flexStartsAt?: Date;
	flexEndsAt?: Date;
	flexEvents?: string;
}

export interface EventShippingItem {
	id?: string;
	adminEventFee: number;
	adminTicketFee: number;
	internetEventFee: number;
	internetTicketFee: number;

	shippingId: string;
	name: string;
	description?: string;

	isElectronic?: boolean;
	isPointOfSale?: boolean;
	isNecessary?: boolean;
	useOnInternet?: boolean;
	useOnAdmin?: boolean;
}

enum AutoFeesMethod {
	Ours,
	Full,
}

export interface ThemeItem {
	logoUrl: string;
	logoPosition: string;
	headerUrlLarge: string;
	headerUrlMobile: string;
	backgroundCss: string;
	textColor: string;
	linkColor: string;
	homeUrl: string;
	headerHtml: string;
}

export interface IFields {
	name: boolean;
	email: boolean;
	phone: boolean;
	address: boolean;
	birthdate: boolean;
}
